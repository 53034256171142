.container {
  max-height: 100%;
}

.table {
  table-layout: fixed;
  width: 100%;

  th.cell {
    background: #f2f2f2;
    font-size: 0.8125rem;
    line-height: 1.25rem;
    font-weight: 500;
  }

  th.cell,
  td.cell {
    white-space: nowrap;
    padding: 0.625rem;
    border: none;
  }

  td.subComponentCell {
    border: none;
    padding: 0;

    .subComponent {
      padding: 0.625rem;
    }
  }
}
