th.colExpand, td.colExpand {
  width: 3.25rem;

  :global(.MuiIconButton-root) {
    padding: 0.25rem;

    :global(.MuiSvgIcon-root) {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.additionalInfo {
  th, td {
    border: none;
    padding: 0 0.5rem 0 0;

    &:nth-of-type(1), &:nth-of-type(2) {
      width: 0;
    }
  }
}

.p1 {
  font-size: 0.8125rem;
  line-height: 1.25rem;
  color: #000000e5;
}

.p2 {
  font-size: 0.6875rem;
  line-height: 1rem;
  color: #00000099;
}
