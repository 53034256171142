.page {
  display: flex;
  min-height: 100vh;
  height: 100%;
  background-color: #262626;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3.5rem;

  .logo {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 1rem;

    .logoText {
      font-size: 32px;
      font-weight: 700;
      color: white;
    }
  }

  .formPanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;

    background-color: white;
    border-radius: 1.25rem;

    padding: 3.75rem;
    box-sizing: border-box;

    min-width: fit-content;
    width: 40%;

    min-height: 45vh;

    .headingContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;

      h1 {
        font-size: 2.25rem;
        font-weight: 600;
      }

      h2 {
        font-size: 1.125rem;
      }
    }

    .form {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .error {
        background-color: #f7f1f1;
        padding: 1rem;
        margin: 1rem 0;
        border-radius: 10px;
      }

      .loginButton {
        margin: 1rem 0;
        min-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
