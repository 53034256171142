//noinspection CssInvalidAtRule
@import-normalize;

@import 'assets/fonts/Metropolis-r11/css/bundles/default.css';
@import 'assets/fonts/clarity-city-1.0.0/css/bundles/default.css';

@import 'scss/bs.custom';

* {
  /* Works on Firefox */
  scrollbar-width: thin;

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 20px;
  }
}

:root {
  --app-font-family: 'Metropolis';
  --app-color-text: #393939;
  --app-color-background: #ffffff;
  --app-color-primary: #262626;

  --bs-body-font-family: var(--app-font-family);
  --bs-body-color: var(--app-color-text);
  --bs-body-bg: var(--app-color-background);
  --bs-primary: var(--app-color-primary);
  --bs-body-line-height: 1.25;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
  color: #303030;
  font-size: 1.375rem;
  font-weight: 600;
  margin: 0;
  line-height: 1;
}
