@use '../../scss/bs.module';

.sidebar {
  min-width: 20rem;
  width: 20rem;
  height: 100vh;

  background-color: #262626;

  display: flex;
  flex-direction: column;
  gap: 3.5rem;

  padding: 2rem 1.5rem;

  .nav {
    flex: auto;
    overflow-y: auto;

    padding: 0.25rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.link {
  padding: 0;
}

.subMenu {
  margin-top: 0.75rem;
}

.navItem {
  display: flex;
  align-items: center;
  gap: 1rem;

  min-height: 3.125rem;
  padding: 0.625rem 1.125rem;

  color: #ffffff;
  font-size: 0.9rem;

  .icon,
  .icon > svg {
    min-width: 1.5rem;
    min-height: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    fill: #ffffff;
    transition: fill 0.15s ease-in-out;
  }

  .content {
    flex: auto;
  }

  .caret {
    min-width: 0.75rem;
    width: 0.75rem;
    height: 0.5rem;
    stroke: #8d949e;
    transition: transform 0.2s ease-in-out;

    &.collapsed {
      transform: rotate(-90deg);
    }
  }

  &.item {
    font-weight: 600;
  }

  &:hover,
  &:focus {
    color: #cccccc;

    .icon {
      fill: #cccccc;
    }

    .caret {
      stroke: black;
    }
  }

  &.active {
    font-weight: 600;

    &.item {
      color: #ffffff;
      background-color: #333333;
      border-radius: 0.345rem;
    }

    &.subItem {
      background: linear-gradient(
          90deg,
          rgba(51, 51, 51, 0) 0%,
          rgba(51, 51, 51, 0.5) 20.27%,
          #333333 48.44%,
          rgba(51, 51, 51, 0.5) 76.04%,
          rgba(51, 51, 51, 0) 100%
      );
      border-radius: 6px;
      color: #ffffff;
    }

    .icon {
      fill: #ffffff;
    }
  }
}
