$font-size: 0.8125rem;
$line-height: 1rem;
$padding: 0.625rem;

.form {
  display: flex;
  gap: 1.25rem;
  margin-top: 0.5rem;
  margin-bottom: 1.25rem;

  & > :nth-child(1) {
    flex: 0.3;
  }

  & > :nth-child(n + 2) {
    flex: 0.2;
  }

  & > :nth-last-child(1) {
    flex: 0;
    flex-basis: auto;
  }
}

.input {
  :global(.MuiInputLabel-root) {
    font-size: $font-size;
    line-height: $line-height;

    &:not(:global(.MuiInputLabel-shrink)) {
      transform: translate($padding, $padding) scale(1);
    }
  }

  :global(.MuiInputBase-root) {
    font-size: $font-size;
    line-height: $line-height;

    :global(.MuiInputBase-input) {
      padding: $padding;
      height: auto;
    }
  }
}

.submit:global(.MuiButtonBase-root) {
  margin-left: auto;
  text-transform: none;
  padding: 0.625rem 1.25rem;
  font-size: $font-size;
  line-height: $line-height;
}
