.wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error {
  max-height: 40%;
  max-width: 80%;
  overflow: auto;
  padding: 0 32px;
  background-color: #ffe6e6;
  border-radius: 20px;
}

