.name {
  font-size: 0.8125rem;
  line-height: 1.25rem;
  color: #000000e5;
}

.email {
  font-size: 0.6875rem;
  line-height: 1rem;
  color: #00000099;
}

.date {
  font-size: 0.6875rem;
  line-height: 1rem;
  color: #00000099;
}

.colSendTo {
  width: 35%;
}

.colSentBy {
  width: 35%;
}

.colStatus {
  width: 25%;
  text-align: end;
}

.colAction {
  width: 5%;
}

.sendTo {
}

.sentBy {
}

.statusHeader {
  text-align: end;
}

.status {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 0.25rem;

  .tag {
    font-size: 0.625rem;
    line-height: 0.75rem;
    padding: 0.3125rem 0.625rem;
    border-radius: 5px;
    width: fit-content;

    background: #0000000d;

    &.accepted {
      background: #06a77d1a;
    }

    &.expired {
      background: #cb64441a;
    }
  }
}
