.page {
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.tabs {
  border-bottom: 1px solid #00000033;

  :global(.MuiTab-root) {
    margin: 0 0.625rem;
    min-width: 11.25rem;
  }

  .tab {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    :global(.MuiChip-root) {
      font-size: 0.625rem;
      height: 1.125rem;
      min-width: 1.125rem;

      :global(.MuiChip-label) {
        padding: 0.25rem;
        line-height: 1;
      }
    }
  }
}

.table {
  overflow: hidden;
}
