@font-face {
  font-family: 'Metropolis';
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis'), url('../../Fonts/Webfonts/WOFF2/Metropolis-Thin.woff2') format('woff2'),
    url('../../Fonts/Webfonts/WOFF/Metropolis-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis'), url('../../Fonts/Webfonts/WOFF2/Metropolis-ExtraLight.woff2') format('woff2'),
    url('../../Fonts/Webfonts/WOFF/Metropolis-ExtraLight.woff') format('woff');
}

/* light */
@font-face {
  font-family: 'Metropolis';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis'), url('../../Fonts/Webfonts/WOFF2/Metropolis-Light.woff2') format('woff2'),
    url('../../Fonts/Webfonts/WOFF/Metropolis-Light.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis'), url('../../Fonts/Webfonts/WOFF2/Metropolis-Regular.woff2') format('woff2'),
    url('../../Fonts/Webfonts/WOFF/Metropolis-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis'), url('../../Fonts/Webfonts/WOFF2/Metropolis-Medium.woff2') format('woff2'),
    url('../../Fonts/Webfonts/WOFF/Metropolis-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis'), url('../../Fonts/Webfonts/WOFF2/Metropolis-SemiBold.woff2') format('woff2'),
    url('../../Fonts/Webfonts/WOFF/Metropolis-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis'), url('../../Fonts/Webfonts/WOFF2/Metropolis-Bold.woff2') format('woff2'),
    url('../../Fonts/Webfonts/WOFF/Metropolis-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis'), url('../../Fonts/Webfonts/WOFF2/Metropolis-ExtraBold.woff2') format('woff2'),
    url('../../Fonts/Webfonts/WOFF/Metropolis-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis'), url('../../Fonts/Webfonts/WOFF2/Metropolis-Black.woff2') format('woff2'),
    url('../../Fonts/Webfonts/WOFF/Metropolis-Black.woff') format('woff');
}
