.container {
  width: calc(100% - 2.5rem);

  margin: 1.5rem 1.25rem;

  background-color: #333333;
  border: 4px solid #333333;
  border-radius: 0.625rem;
}

.menu {
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;

  .item {
    width: calc(100% - 1rem);
    margin: 0.5rem;
    padding: 0.625rem 1rem;
    min-height: 2rem;
    font-size: 0.75rem;
  }

  .logout {
    background-color: #f15f5f;
    color: white;
    justify-content: center;
  }
}

.profileButton {
  width: 100%;
  min-height: 3rem;
  padding: 0.625rem 1rem;

  background-color: #333333;
  border: none;
  border-radius: 0.625rem;

  color: #ffffff;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: initial;

  display: flex;
  align-items: center;
  gap: 0.75rem;

  .image {
    min-width: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    fill: currentColor;
  }

  .name {
    flex: auto;
  }

  .chevron {
    width: 0.75rem;
    height: 0.5rem;
    transform: rotate(-90deg);
    transition: transform 0.15s ease-in-out;

    stroke: currentColor;

    &.expanded {
      transform: rotate(0);
    }
  }
}
