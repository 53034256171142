.app {
  display: flex;

  .content {
    flex: auto;

    display: flex;
    flex-direction: column;
    max-height: 100vh;

    .mainContent {
      max-height: 100%;
      overflow: hidden;
      padding: 40px;
    }
  }
}
