@import '../bs.module';

$btn-padding-x: 1.25em;
$btn-padding-y: 0.875em;
$btn-line-height: 1;

@import '~bootstrap/scss/buttons';

.btn {
  white-space: nowrap;
  text-transform: uppercase;
}

.btn-normal {
  @include button-variant($background: white, $border: #555870, $color: #555870);
  letter-spacing: 0;
}

.btn-transparent {
  @include button-variant($background: transparent, $border: transparent, $color: #555870);
  border: none;
  padding: 0.125rem;
  text-transform: initial;
}
