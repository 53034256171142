.menu {
  max-width: 100%;
  width: 100%;

  .menuItem {
    overflow: hidden;
    text-overflow: ellipsis;
    @extend .companyName;
  }
}

.selected {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  text-transform: none;

  padding: 0 2rem;

  .appLogo {
    & > svg {
      height: 3rem;
      width: 3rem;
    }
  }

  .selectedDetails {
    flex: auto;

    display: flex;
    flex-direction: column;
    align-items: start;

    overflow: hidden;

    .appName {
      font-size: 1.355rem;
      line-height: 1.375rem;
      font-weight: 700;
      color: #ffffff;
    }

    .companyName {
      color: #f5f5f5;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      font-size: 0.9rem;
      line-height: 1.625rem;
    }
  }
}

.companyName {
  font-size: 0.9rem;
}
